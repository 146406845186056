import React, { useState } from 'react'
import axios from 'axios'
import { Button, Typography, Modal } from 'antd'
const { Paragraph } = Typography

const UploadComponent = () => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [uploadResponse, setUploadResponse] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleUpload = async () => {
    setLoading(true)

    if (!selectedFile) {
      console.error('Please select a file.')
      return
    }

    console.log(selectedFile)
    // return

    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': selectedFile.type,
    }

    // ? to make the upload object public read all users add: &predefinedAcl=publicRead

    const apiUrl1 = `https://storage.googleapis.com/upload/storage/v1/b/${localStorage.getItem(
      'name'
    )}/o?uploadType=media&name=thumbnails/${selectedFile.name}`

    const apiUrl2 = `https://storage.googleapis.com/upload/storage/v1/b/${localStorage.getItem(
      'name'
    )}/o?uploadType=media&name=images/${selectedFile.name}`

    const config1 = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl1,
      headers: headers,
      data: selectedFile,
    }
    const config2 = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl2,
      headers: headers,
      data: selectedFile,
    }

    try {
      const response1 = await axios(config1)
      const response2 = await axios(config2)

      navigator.clipboard.writeText(
        `${`https://storage.cloud.google.com/${response1.data.bucket}/${response1.data.name}`
          .split('/')
          .slice(-1)}`
      )
      setUploadResponse({
        thumbnail: {
          ...response1.data,
          src: `https://storage.cloud.google.com/${response1.data.bucket}/${response1.data.name}`,
        },
        original: {
          ...response2.data,
          src: `https://storage.cloud.google.com/${response2.data.bucket}/${response2.data.name}`,
        },
      })
      console.log(response1.data)
      console.log(response2.data)
      setLoading(false)
    } catch (error) {
      console.error('Error uploading file:', error)
      setLoading(false)
    }
  }

  const onClose = () => {
    setUploadResponse(null)
    setUploadResponse(null)
    window.location.reload()
  }

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <Button onClick={handleUpload}>Upload</Button>
      {loading && (
        <div class="overlayLoader">
          <div class="spinner"></div>
        </div>
      )}
      {uploadResponse && (
        <Modal
          title="Upload success"
          open={uploadResponse}
          footer={null}
          onOk={() => onClose()}
          onCancel={() => onClose()}
        >
          <Paragraph copyable>
            {uploadResponse?.thumbnail?.src?.split('/').slice(-1)}
          </Paragraph>
          <Paragraph
            copyable={{
              text:
                localStorage.getItem('tokenResponse_hd') === 'undefined'
                  ? uploadResponse?.thumbnail?.src
                  : uploadResponse?.thumbnail?.src.split(
                      'storage.cloud.google.com/',
                      2
                    )[1], // all ark-am buckets will removed before this portion
            }}
          >
            Thumbnail
          </Paragraph>
          <Paragraph
            copyable={{
              text:
                localStorage.getItem('tokenResponse_hd') === 'undefined'
                  ? uploadResponse?.original?.src
                  : uploadResponse?.original?.src.split(
                      'storage.cloud.google.com/',
                      2
                    )[1], // all ark-am buckets will removed before this portion
            }}
          >
            Original
          </Paragraph>
        </Modal>
      )}
      <Button type="link" onClick={() => setIsModalOpen(true)}>
        FAQ
      </Button>

      <Modal
        title="FAQ"
        open={isModalOpen}
        footer={null}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <p>- Uploading same name image will relace the old one.</p>
        <p>
          - Currently the thumbnail & real image quality, ratio, size is same
          because we don't have any server for this frontend
        </p>
        <p>- Search: Search is case sensitive</p>
        <p>
          - Search: Currently working only with first portion match ( if you
          type middle portion of any name it will not appear )
        </p>
        <p>- Multiple file upload is not supported, use cloud console</p>
      </Modal>
    </div>
  )
}

export default UploadComponent
