import { GoogleOAuthProvider } from '@react-oauth/google'

import Btn from './btn'

function App() {
  return (
    <GoogleOAuthProvider clientId="461969635424-t49ufrh32tp31alllkn231sf1an8ia41.apps.googleusercontent.com">
      <Btn />
    </GoogleOAuthProvider>
  )
}

export default App
