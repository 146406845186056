import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import {
  Button,
  Typography,
  Modal,
  Tooltip,
  Input,
  Popconfirm,
  Row,
  Col,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useGoogleLogin } from '@react-oauth/google'
import PhotoAlbum from 'react-photo-album'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

import UploadComponent from './UploadComponent'

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

const { Paragraph } = Typography

function Btn() {
  const [name, setName] = useState(localStorage.getItem('name') || '')
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('access_token') || ''
  )
  const [photos, setPhotos] = useState([])
  const [index, setIndex] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [showOverlay, setShowOverlay] = useState(null)
  const [nextPageToken, setNextPageToken] = useState('')

  const handleLogout = (keys) => {
    keys.forEach((key) => {
      localStorage.removeItem(key)
    })
    window.location.reload()
  }

  const getObjectLists = async (searchQuery) => {
    setLoading(true)
    let url = `https://storage.googleapis.com/storage/v1/b/${name}/o?delimiter=/&prefix=thumbnails/&maxResults=99`

    if (searchQuery)
      url = `https://storage.googleapis.com/storage/v1/b/${name}/o?delimiter=/&prefix=thumbnails/${searchQuery}&maxResults=99`

    if (nextPageToken) url = url.concat(`&pageToken=${nextPageToken}`)

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      //   url: `https://storage.googleapis.com/storage/v1/b/${name}/o`, // List from root level of bucket
      url: url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data))
        // console.log(response.data)
        // console.log(response.data.items)
        let imageArray = []

        response.data.items?.forEach((el) => {
          // console.log(
          //   `https://storage.cloud.google.com/${el.bucket}/${el.name}`
          // )
          if (el.contentType !== `text/plain`) {
            // excluding folder mainly for now
            imageArray.push({
              src: `https://storage.cloud.google.com/${el.bucket}/${el.name}`,
              width: 100,
              height: 100,
              name: el.name,
              selfLink: el.selfLink,
              size: el.size,
              updated: el.updated,
              contentType: el.contentType,
            })
          }
        })

        setLoading(false)
        if (nextPageToken) {
          // setPhotos((prev) => [...prev, ...imageArray])// ! To remove previous images from the DOM currently not doing this
          setPhotos((prev) => [...imageArray])
        } else {
          // Here replacing all previous images (useful for after delete, or other changes which will not append images with old images)
          setPhotos((prev) => [...imageArray])
        }

        // console.log(searchQuery)
        // console.log(response.data.nextPageToken)
        if (response.data.nextPageToken) {
          setNextPageToken(response.data.nextPageToken)
        } else {
          setNextPageToken('') // important
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        console.log(error?.response?.status === 401)
        if (error?.response?.status === 401)
          handleLogout(['access_token', 'tokenResponse_hd']) // if access_token expired
      })
  }

  const handleDelete = async (selfLink) => {
    try {
      setLoading(true)
      console.log(selfLink)
      console.log(selfLink.replace('thumbnails', 'images')) // will only replace the first "thumbanails" string will not replace the second "thumbnails" string (if present at image name)
      const response = await axios.delete(
        // `https://storage.googleapis.com/storage/v1/b/${name}/o/${objectName}`,
        selfLink,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      console.log('Delete successful:', response)
    } catch (error) {
      setLoading(false)
      console.error('Error deleting object:', error)
      // Handle error or display an error message to the user
    }
    try {
      const response2 = await axios.delete(
        selfLink.replace('thumbnails', 'images'),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      console.log('Delete successful:', response2)
      window.location.reload() // rather "getObjectLists()" using reload
    } catch (err) {
      console.error('Error deleting object:', err)
    }
  }

  const handleRename = async (photo, newName) => {
    console.log(photo)
    const folderName = photo.name.split('/')[0]
    const mimeType = photo.contentType.split('/')[1]

    var endpoint = `${photo.selfLink}/rewriteTo/b/${name}/o/${folderName}%2F${newName}.${mimeType}`
    endpoint.replace('https://www', 'https://storage')
    var endpoint2 = endpoint.replace(/\/thumbnails%/g, '/images%')

    console.log(endpoint)
    console.log(endpoint2)
    // return
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      // 'Content-Length': 0,
    }
    const config1 = {
      method: 'post',
      url: endpoint,
      headers: headers,
    }
    const config2 = {
      method: 'post',
      url: endpoint2,
      headers: headers,
    }
    setLoading(true)
    try {
      const response1 = await axios(config1)
    } catch (e) {
      console.log(e)
    }
    try {
      const response2 = await axios(config2)
    } catch (e) {
      console.log(e)
    }
    handleDelete(photo.selfLink)
  }

  const updateName = (photo, value) => {
    console.log(photo, value)
    handleRename(photo, value)
  }

  const login = useGoogleLogin({
    // flow: 'auth-code', // to get "code" for "refresh_token"
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse)
      setAccessToken(tokenResponse.access_token)
      localStorage.setItem('access_token', tokenResponse.access_token)
      localStorage.setItem('tokenResponse_hd', tokenResponse.hd)
    },
    onError: () => {
      handleLogout(['name', 'access_token', 'tokenResponse_hd'])
    },
    scope:
      'https://www.googleapis.com/auth/devstorage.read_only https://www.googleapis.com/auth/devstorage.read_write https://www.googleapis.com/auth/devstorage.full_control',
  })

  useEffect(() => {
    if (accessToken && name) {
      getObjectLists()
    }
  }, [])

  useEffect(() => {
    const sortedData = photos.sort((a, b) => {
      const aUpdated = new Date(a.updated)
      const bUpdated = new Date(b.updated)
      return bUpdated - aUpdated // Sort in descending order
    })
    setPhotos(sortedData)
  }, [photos])

  // useEffect(() => {
  //   if (nextPageToken) getObjectLists() // ! Becareful about this usecase, this could be buggy, need to test this roughly
  // }, [nextPageToken])

  const imageContainerStyle = {
    maxWidth: '1200px', // Adjust the max-width as needed
    margin: '0 auto',
    padding: '16px',
  }

  const imageDivStyle = {
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '100%', // 50% for a 1:1 aspect ratio
  }

  const imageStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    objectFit: 'cover', // This ensures the image covers the entire container
  }

  const tokenResponse_hd = localStorage.getItem('tokenResponse_hd')
  console.log(tokenResponse_hd)

  return (
    <div>
      {accessToken ? (
        <Button
          danger
          type="link"
          style={{ marginRight: 5 }}
          onClick={() => {
            handleLogout(['name', 'access_token', 'tokenResponse_hd'])
          }}
        >
          Logout
        </Button>
      ) : (
        <Button onClick={() => login()} type="primary">
          Sign in with Google 🚀
        </Button>
      )}
      {JSON.stringify(nextPageToken)}
      {accessToken && (
        <>
          <Input
            type="Name"
            value={name}
            style={{ display: 'inline-block', width: 200, marginRight: 5 }}
            onChange={(e) => {
              setName(e.target.value)
              localStorage.setItem('name', e.target.value)
            }}
          />
          <Button
            type="primary"
            style={{ marginRight: 5 }}
            onClick={() => {
              getObjectLists()
            }}
          >
            Get Files
          </Button>
          <Input.Search
            allowClear
            placeholder="Input search text"
            onSearch={(value) => {
              getObjectLists(value)
            }}
            style={{
              width: 200,
            }}
          />
          Total: {photos.length * 2}
          {nextPageToken && (
            <button onClick={() => getObjectLists(undefined)}>Load more</button>
          )}
        </>
      )}
      {accessToken && <UploadComponent />}

      {loading && (
        <div class="overlayLoader">
          <div class="spinner"></div>
        </div>
      )}
      <br />
      <hr />
      <div style={imageContainerStyle}>
        <Row gutter={[16, 16]}>
          {photos.map((photo, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
              <div style={imageDivStyle}>
                <img
                  src={photo.src}
                  alt={`Image ${index + 1}`}
                  style={imageStyle}
                  onClick={() => setShowOverlay(photo?.src)}
                />
              </div>
              <Tooltip title="Please don't add extention (like .png / .jpeg )">
                <Paragraph editable={{ onChange: (n) => updateName(photo, n) }}>
                  {photo.src.split('/').slice(-1)}
                </Paragraph>
              </Tooltip>
              <Paragraph
                copyable={{
                  text:
                    localStorage.getItem('tokenResponse_hd') === 'undefined'
                      ? photo.src.replace('thumbnails', 'images')
                      : photo.src
                          .replace('thumbnails', 'images')
                          .split('storage.cloud.google.com/', 2)[1], // all ark-am buckets will removed before this portion
                }}
              >
                Original
              </Paragraph>
              <Paragraph
                copyable={{
                  text:
                    localStorage.getItem('tokenResponse_hd') === 'undefined'
                      ? photo.src
                      : photo.src.split('storage.cloud.google.com/', 2)[1], // all ark-am buckets will removed before this portion
                }}
              >
                Thumbnail {(photo.size / 1024 / 1024).toFixed(3)} MB
              </Paragraph>
              <Popconfirm
                title="Delete the photo?"
                description="Are you sure to delete this photo?"
                onConfirm={() => handleDelete(photo.selfLink)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Col>
          ))}
        </Row>
      </div>
      {/* {!loading && (
        <PhotoAlbum
          columns={1}
          layout="rows"
          photos={photos}
          renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => {
            return (
              <div style={{ position: 'relative', ...wrapperStyle }}>
                {renderDefaultPhoto({ wrapped: true })}
                <Tooltip title="Please don't add extention (like .png / .jpeg )">
                  <Paragraph
                    editable={{ onChange: (n) => updateName(photo, n) }}
                  >
                    {photo.src.split('/').slice(-1)}
                  </Paragraph>
                </Tooltip>
                <Paragraph
                  copyable={{
                    text: photo.src.replace('thumbnails', 'images'),
                  }}
                >
                  Original
                </Paragraph>
                <Paragraph
                  copyable={{
                    text: photo.src,
                  }}
                >
                  Thumbnail {(photo.size / 1024 / 1024).toFixed(3)} MB
                </Paragraph>
                <Popconfirm
                  title="Delete the photo?"
                  description="Are you sure to delete this photo?"
                  onConfirm={() => handleDelete(photo.selfLink)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
                {photo.title && (
                  <div
                    style={{
                      position: 'absolute',
                      overflow: 'hidden',
                      backgroundColor: 'rgba(255 255 255 / .6)',
                      inset: 'auto 0 0 0',
                      padding: 8,
                    }}
                  >
                    {photo.title}
                  </div>
                )}
              </div>
            )
          }}
          onClick={(img) => setShowOverlay(img?.photo?.src)}
        />
      )} */}

      {showOverlay && (
        <div className="overlay" onClick={() => setShowOverlay(false)}>
          <img
            src={showOverlay.replace('thumbnails', 'images')} // showing same name real image from "images" folder
            className="overlay-image"
          />
          <button
            className="overlay-close-button"
            onClick={() => setShowOverlay(false)}
          >
            Close
          </button>
        </div>
      )}

      {nextPageToken && (
        <button onClick={() => getObjectLists(undefined)}>Load more</button>
      )}

      {/* <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      /> */}
    </div>
  )
}

export default Btn
